<template>
  <div>
    <admin-title title="Project Templates">
      <template v-slot:actions>
        <v-btn @click="$refs.addTemplate.dialog = true" color="accent gradient">Create New</v-btn>
      </template>
    </admin-title>

    <div>
      <v-row>
        <!-- CATEGORIES -->
        <v-col cols="3">
          <v-card class="custom-shadow" outlined>
            <v-card flat>
              <v-card-title class="body-1 font-weight-bold">
                <span>Categories</span>
                <v-spacer></v-spacer>
                <v-btn 
                  small
                  icon 
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <Categories v-bind:categories.sync="categs" />
            </v-card>
          </v-card>
        </v-col>

        <!-- TEMPLATES -->
        <v-col cols="9">
          <preloader v-if="status.getting" message="Please wait..." />

          <v-text-field
            v-if="templates.length"
            v-model="search"
            placeholder="Search template"
            class="custom-shadow mb-7"
            append-icon="mdi-magnify"
            hide-details
            clearable
            outlined
            rounded
          ></v-text-field>

          <v-alert
            :value="!confTemplates.length && !status.getting"
            color="info"
            border="left"
            text
          >
            No template found.
          </v-alert>

          <template v-if="confTemplates.length && !status.getting">
            <v-card outlined>
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Category</th>
                      <th class="text-center">Published</th>
                      <th>Created</th>
                      <th width="30">Action</th>
                    </tr>
                  </thead>
                  <v-fade-transition
                    name="templates"
                    tag="tbody"
                    group
                  >
                    <tr v-for="template in confTemplates" :key="template.id">
                      <td class="font-weight-bold">
                        <router-link 
                          :to="{ name: 'Project', params: { id: template.id } }"
                          class="primary--text"
                        >
                          {{ template.name }}
                        </router-link>
                      </td>
                      <td>{{ getCategoryName(template.category).name  }}</td>
                      <td class="text-center">
                        <v-icon :color="template.published ? 'success' : 'grey lighten-2'">mdi-checkbox-marked-circle-outline</v-icon>
                      </td>
                      <td class="caption">{{ template.createdAt | formatDate }}</td>
                      <td>
                        <v-menu bottom left offset-y transition="slide-x-transition" nudge-bottom="12">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="primary"
                              class="subtle"
                              v-on="on"
                              small
                              icon
                            >
                              <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                              :to="{ name: 'Project', params: { id: template.id } }"
                              dense 
                            >
                              <v-list-item-title>
                                <v-icon small left>mdi-palette-advanced</v-icon> 
                                <span>Builder</span>
                              </v-list-item-title>
                            </v-list-item>
                            
                            <v-list-item
                              v-if="template.published"
                              @click="unpublishTemplate(template)"
                              dense 
                            >
                              <v-list-item-title>
                                <v-icon small left>mdi-close-circle-outline</v-icon> 
                                <span>Unpublish</span>
                              </v-list-item-title>
                            </v-list-item>
                            
                            <v-list-item
                              v-if="!template.published"
                              @click="publishTemplate(template)"
                              dense 
                            >
                              <v-list-item-title>
                                <v-icon small left>mdi-checkbox-marked-circle-outline</v-icon> 
                                <span>Publish</span>
                              </v-list-item-title>
                            </v-list-item>
                            
                            <v-list-item
                              @click="editTemplate(template)"
                              dense 
                            >
                              <v-list-item-title>
                                <v-icon small left>mdi-pencil</v-icon> 
                                <span>Edit Project</span>
                              </v-list-item-title>
                            </v-list-item>
                            
                            <v-list-item 
                              @click="confirmDelete(template)"
                              dense 
                            >
                              <v-list-item-title class="error--text">
                                <v-icon color="error" small left>mdi-delete</v-icon> 
                                <span>Delete Project</span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </v-fade-transition>
                </template>
              </v-simple-table>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </div>

    <!-- TEMPLATE DIALOG -->
    <CreateTemplateDialog ref="addTemplate" />

    <!-- CONFIRM DELETE DIALOG -->
    <confirm-delete
      @confirmed="deleteConfirmed()"
      @cancel="closeDeleteDialog()"
      :deleting="status.deletingTemplate"
      :message="deleteMessage"
      :show="deleteDialog"
      textConfirmation="Template"
      captcha
    />
  </div>
</template>

<script>
import Categories from './Categories'
import { mapState, mapActions } from 'vuex'
import CreateTemplateDialog from './CreateTemplateDialog'
import { orderBy } from 'lodash'

export default {
  metaInfo: {
    title: 'Project Templates'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      categs: [],
      search: null,
      toDelete: null,
      deleteDialog: false,
      deleteMessage: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.allProjects.status,
      templates: (state) => state.allProjects.templates,
      categories: (state) => state.projectTemplates.categories,
    }),

    confTemplates: function () {
      let templates = this.templates

      templates = templates.filter(template => {
        return this.categs.includes(template.category) 
      })

      if (this.search) {
        templates = templates.filter(template => {
          return template.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return orderBy(templates, 'createdAt', 'desc')
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Categories,
    CreateTemplateDialog,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('allProjects', [
      'getProjectTemplates',
      'unpublishTemplate',
      'publishTemplate',
      'deleteTemplate'
    ]),

    getCategoryName(id) {
      let category = this.categories.find(c => c.id == id)
      return category || {}
    },

    confirmDelete(template) {
      this.toDelete = template
      this.deleteDialog = true
      this.deleteMessage = `Delete project template <strong>${template.name}</strong>?`
    },

    closeDeleteDialog() {
      this.deleteDialog = false
      this.toDelete = null
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteTemplate(this.toDelete))
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    editTemplate(template) {
      this.$refs.addTemplate.dialog = true
      this.$store.commit('projects/setData', template)
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstTemplateLoad) 
      this.getProjectTemplates()
  }
}
</script>